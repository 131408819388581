import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Router as ReactRouter, Redirect} from 'react-router-dom';
import loadable from '@loadable/component';
import isEmpty from 'lodash.isempty';
import {addToast} from "../../store/toasts/toasts.action";

import {authentication} from '../../helpers/authentication.helper';

import {
    catalogRoutes,
    landingRoutes,
    staticPagesRoutes,
    unAuthenticatedRoutes,
    userRoutes,
} from './routeDefinition';

//helpers
import {history} from '../../helpers/history';
import {analyticsHelper} from '../../helpers/analytics.helper';

//Layout
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import AppLayout from '../../layout/AppLayout/AppLayout';
import UserLayout from '../UserLayout/UserLayout';
//components
import PrivateRoute from './PrivateRoute';
import {authenticationActions} from '../../store/authentication/authentication.action';
import ResetPasswordLayout from '../../layout/ResetPasswordLayout/ResetPasswordLayout';
import {localStorageService} from '../../services/localStorage.service';
import {appActions} from '../../store/app/app.action';

import i18n from "../../i18n/i18n";
import Loading from "../Loading/Loading";

// home
const Home = loadable(() => import('../Home/Home'));
// force reset password
const ForceResetPassword = loadable(() => import('../ForceResetPassword/ForceResetPassword'));
// landing pages LandingPage
const LandingPage = loadable(() => import('../Landing/Landing'));



class Router extends React.Component {

    constructor(props) {
        super(props);


        const {accessToken, role} = props.authentication;
        this.state = {searchParams: {}}

        if (authentication.isAuthenticated(role, accessToken)) {
            this.imAuthenticated = true;
            // get client data
            this.props.getAccount();
            // init application
            this.props.initApp();
        }

        this.onChangeRoute = this.onChangeRoute.bind(this);
    }

    componentDidMount() {
        const {account, role, accessToken} = this.props.authentication;
        const isUserLogged = !!localStorageService.getAccessToken();
        const lastLanguageUsed = localStorage.getItem('reda_catalog_current_lang');

        // Se non sono loggato e la pagina richiesta richiede di esserlo
        const routeNeedAuth = !unAuthenticatedRoutes
            .concat(landingRoutes)
            .find(route => route.path === history.location.pathname);
        if (routeNeedAuth && !authentication.isAuthenticated(role, accessToken)) {
            history.push('/login');
        }

        if (lastLanguageUsed) {
            i18n.changeLanguage(lastLanguageUsed).then();
        } else if (isUserLogged) {
            localStorage.setItem('reda_catalog_current_lang', account.language);
            i18n.changeLanguage(account.language).then();
        }
        history.listen(this.onChangeRoute);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {role, account, accessToken} = this.props.authentication;
        if (
            (
                prevProps.authentication?.role !== role ||
                prevProps.authentication.account?.hasToChangePassword !== account.hasToChangePassword
            ) &&
            ['/login', '/404'].indexOf(history.location.pathname) !== -1
        ) {
            // persist account on localstorage
            localStorageService.setLoggedAccount(this.props.authentication);
            history.push(this.getEntryPoint());
            const lastLanguageUsed = localStorage.getItem('reda_catalog_current_lang');
            if (lastLanguageUsed) {
                i18n.changeLanguage(lastLanguageUsed).then();
            } else
                localStorage.setItem('reda_catalog_current_lang', account.language);
                i18n.changeLanguage(account.language).then();
        }

        // Se non sono loggato e la pagina richiesta richiede di esserlo
        const routeNeedAuth = !unAuthenticatedRoutes.concat(landingRoutes).find(route => route.path === history.location.pathname);
        if (routeNeedAuth && !authentication.isAuthenticated(role, accessToken)) {
            this.props.showToast({
                type: 'danger',
                text: i18n.t('Login.authDenied'),
                timeout: 8000
            });
            history.push('/login');
        }

    }

    onChangeRoute(location, action) {
        window.scrollTo(0, 0);
        analyticsHelper.pageViewGATrack(location.pathname);

        const searchParams = new URLSearchParams(window.location.search);
        !isEmpty(window.location.search) && this.checkQueryParams(searchParams)
    }

    checkQueryParams(searchParams) {
        const searchParamsKeys = [];
        for (const key of searchParams.keys()) {
            searchParamsKeys.push(key)
        }

        const updatedParams = searchParamsKeys.reduce((acc, key) => {
            acc[key] = searchParams.get(key)
            return acc
        }, {});
        this.setState({searchParams: updatedParams})
    }

    getEntryPoint = () => {
        const {accessToken, role, account} = this.props.authentication;
        const {searchParams} = this.state;
        let location = '/login';
        if (authentication.isAuthenticated(role, accessToken)) {
            location = '/home';
            if (account.hasToChangePassword) {
                location = '/force-reset-password';
            }
            // redirect from landing to catalog
            if (!isEmpty(searchParams) && searchParams?.page) {
                location = `/current/${searchParams.page}?landing=true&type=${searchParams.type}&filter=${searchParams.filter}`;
            }
        }
        return location;
    }

    getNotFoundEndPoint = () => {
        const {account, accessToken, role} = this.props.authentication;
        if (!authentication.isAuthenticated(role, accessToken)) {
            return '/login';
        }
        return account.hasToChangePassword ? '/force-reset-password' : '/404';
    }

    getVisibleRoutes = () => {
        const {accessToken, role, account} = this.props.authentication;
        if (!authentication.isAuthenticated(role, accessToken)) {
            return 'unauthorized';
        }
        return account.hasToChangePassword ? 'force-password' : 'authorized';
    }

    render() {
        const { account, role, accessToken } = this.props.authentication;
        const imAuthenticated = authentication.isAuthenticated(role, accessToken);
        return (
            <ReactRouter history={history}>
                <Switch>
                    {/* UNAUTHENTICATED ROUTS */}
                    <Route exact path={unAuthenticatedRoutes.map(route => route.path)}>
                        <AuthLayout>
                            <Switch>
                                {unAuthenticatedRoutes.map(route => (
                                    <Route key={route.path} exact path={route.path} component={route.component}/>
                                ))}
                            </Switch>
                        </AuthLayout>
                    </Route>

                    {/* LANDING ROUTES */}
                    <Route
                      exact
                      path={
                        landingRoutes.map(route => route.path)
                            .filter(route => route.hasOwnProperty('available') ? route.available : true)
                            .filter(route => route.hasOwnProperty('active') ? route.active : true)
                        }
                    >
                        <Switch>
                            {landingRoutes
                                .filter(route => route.hasOwnProperty('available') ? route.available : true)
                                .filter(route => route.hasOwnProperty('active') ? route.active : true)
                                .map(route => (
                                    <Route key={route.path} exact path={route.path}
                                           render={(props) => (
                                               <LandingPage
                                                   {...props}
                                                   type={route.type}
                                                   page={route.page}
                                                   color={route.color}
                                               />
                                           )}
                                    />
                                ))}
                        </Switch>
                    </Route>

                    {/* FORCE PASSWORD */}
                    {this.getVisibleRoutes() === 'force-password' &&
                        <Route exact path={['/force-reset-password']}>
                            <ResetPasswordLayout>
                                <Switch>
                                    <PrivateRoute exact path="/force-reset-password" component={ForceResetPassword}/>
                                    <Redirect exact from="*" to="/force-reset-password"/>
                                </Switch>
                            </ResetPasswordLayout>
                        </Route>
                    }

                    {/* AUTHENTICATED ROUTS */}
                    {
                        isEmpty(account) &&
                        <Loading/>
                    }
                    {
                        this.getVisibleRoutes() === 'authorized' &&
                        <PrivateRoute exact path="/home" component={Home}/>
                    }
                    {
                        this.getVisibleRoutes() === 'authorized' &&
                        <Route exact path={[...userRoutes.map(route => route.path)]}>
                            <AppLayout>
                                <Switch>
                                    <UserLayout>
                                        {userRoutes.map(route => (
                                            <PrivateRoute key={route.path} exact path={route.path} component={route.component}/>
                                        ))}
                                    </UserLayout>
                                </Switch>
                            </AppLayout>
                        </Route>
                    }
                    {
                        this.getVisibleRoutes() === 'authorized' &&
                        <Route exact
                               path={[
                                   ...catalogRoutes,
                                   ...staticPagesRoutes,
                                   ...userRoutes
                               ].filter(route => route.hasOwnProperty('available') ? route.available : true).map(route => route.path)}
                        >
                            <AppLayout>
                                <Switch>
                                    {[...staticPagesRoutes, ...catalogRoutes]
                                        .filter(route => route.hasOwnProperty('available') ? route.available : true)
                                        .map(route => (
                                            <PrivateRoute key={route.path} exact path={route.path} component={route.component} />
                                        ))
                                    }
                                </Switch>
                            </AppLayout>
                        </Route>
                    }

                    <Redirect exact from="/" to={this.getEntryPoint()} push={true}/>
                    {/* NOT FOUND */}
                    <Redirect exact from="*" to={this.getNotFoundEndPoint()} push={true}/>
                </Switch>
            </ReactRouter>
        );
    }
}

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    brand: state.brand
})

const mapDispatchToProps = dispatch => ({
    getAccount: () => dispatch(authenticationActions.getAccount()),
    initApp: () => dispatch(appActions.init()),
    showToast: (options) => dispatch(addToast(options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Router);
